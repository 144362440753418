import { FC, Fragment, useState } from 'react'
import { IAccordionWrapper } from './IAccordionWrapper'
import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { CustomIconButton } from '../UI/Buttons/CustomIconButton/CustomIconButton'

export const AccordionWrapper:FC<IAccordionWrapper> = (props) => {
  const {
    children,
    sxPropsAccord,
    sxPropsAccordSummary,
    title,
    isOpen = false,
    sxPropsText,
    showDivider = true,
    leftText,
    sxPropsLeftText,
    sxAccordionDetails,
  } = props

  const [isOpenAccordion, setOpenAccordion] = useState(isOpen ? isOpen : false)

  return (
    <div>
      <Accordion 
        elevation={0}
        expanded={isOpenAccordion}
        disableGutters
        TransitionProps={{timeout:0}}
        sx={{
          pl:0,
          pr:0,
          background:'inherit', 
          overflow:'hidden',
          ...sxPropsAccord
        }}
      >
        <AccordionSummary
          expandIcon={<CustomIconButton><ExpandMore fontSize="medium" sx={{transform:'rotate(-90deg)'}}/></CustomIconButton>}
          onClick={()=>setOpenAccordion((prev)=>!prev)}
          sx={{
            pl:0,
            pr:0,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
            ...sxPropsAccordSummary
          }}
        >
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{width:'100%'}}>
            <Typography sx={{fontWeight:'bold', ml:0.5, ...sxPropsText}}>{title}</Typography>
            <Typography sx={{fontWeight:'bold', ...sxPropsLeftText}}>{leftText}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{pl:0, pr:0, ...sxAccordionDetails}}>
          {children}
        </AccordionDetails>
      </Accordion>
      {!isOpenAccordion && showDivider && <Divider/>}
    </div>
  )
}
