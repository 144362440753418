import { useEffect } from "react";
import userStore from "../../store/user.store";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ROUTES, privateRoutesApp, publicRoutesApp } from "../../shared/routes/routes";
import { HomePage } from "../../pages/HomePage/HomePage";
import { observer } from "mobx-react-lite";

export const PrivateRoute = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("token") !== "undefined") {
      userStore.setAuth(true);
      // navigate(ROUTES.HOME)
    } else {
      // navigate(ROUTES.LOGIN)
    }
  }, []);

  return (
    <>
      {localStorage.getItem("token") && localStorage.getItem("token") !== "undefined" ? (
        <Routes>
          {privateRoutesApp.map(({ path, element: Element }) => (
            <Route key={path} path={path} element={<Element />} />
          ))}
          <Route path="*" element={<HomePage />} />
        </Routes>
      ) : (
        <Routes>
          {publicRoutesApp.map(({ path, element: Element }) => (
            <Route key={path} path={path} element={<Element />} />
          ))}
          <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
        </Routes>
      )}
    </>
  );
});
