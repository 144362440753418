import { ALL_RULES } from "./interface/EnumRights";

export const RULES = {
  integrations: {
    admin: ALL_RULES.ADMIN,
    get: ALL_RULES.get_integrations,
    edit_self: ALL_RULES.edit_self_integrations,
    edit_others: ALL_RULES.edit_other_integrations,
    create_integration: ALL_RULES.create_integration,
  },
  channels: {
    admin: ALL_RULES.ADMIN,
    get: ALL_RULES.get_channels,
    edit: ALL_RULES.edit_channels,
    create_channel: ALL_RULES.create_channel,
  },
  finances: {
    admin: ALL_RULES.ADMIN,
    lk_finances: ALL_RULES.lk_finances,
  },
  dashboard: {
    admin: ALL_RULES.ADMIN,
    search_head: ALL_RULES.search_head,
    release_head: ALL_RULES.release_head,
    read_coefs: ALL_RULES.read_coefs,
    edit_plan: ALL_RULES.edit_plan,
    concord: ALL_RULES.concord,
    view_motivation_page: ALL_RULES.VIEW_MOTIVATION_PAGE,
  },
  analytics: {
    admin: ALL_RULES.ADMIN,
    view_analytics_page: ALL_RULES.VIEW_ANALYTICS_PAGE,
  },
  counteragents: {
    admin: ALL_RULES.ADMIN,
    view_counteragent_page: ALL_RULES.VIEW_COUNTERAGENT_PAGE,
  },
  tz: {
    admin: ALL_RULES.ADMIN,
    view_tz_page: ALL_RULES.VIEW_TZ_PAGE,
  },
  users: {
    admin: ALL_RULES.ADMIN,
  },
};

export const ACCESS_RULES = {
  integrations: [RULES.integrations.admin, RULES.integrations.get],
  create_channels: [RULES.channels.admin, RULES.channels.create_channel],
  create_integration: [RULES.integrations.admin, RULES.integrations.create_integration],
  channels: [],
  finances: [RULES.finances.admin, RULES.finances.lk_finances],
  dashboard: [
    RULES.dashboard.admin,
    RULES.dashboard.edit_plan,
    RULES.dashboard.search_head,
    RULES.dashboard.release_head,
  ],
  dashboardPlan: [RULES.dashboard.admin, RULES.dashboard.edit_plan],
  dashboardMotivation: [
    RULES.dashboard.admin,
    RULES.dashboard.read_coefs,
    RULES.dashboard.view_motivation_page,
  ],
  dashboardAgreements: [RULES.dashboard.admin, RULES.dashboard.concord],
  dashboardRules: [RULES.dashboard.admin],
  dashboardPrepayment: [RULES.dashboard.admin],
  analytics: [RULES.analytics.admin, RULES.analytics.view_analytics_page],
  counteragents: [RULES.counteragents.admin, RULES.counteragents.view_counteragent_page],
  tz: [RULES.tz.admin, RULES.tz.view_tz_page],
  users: [RULES.users.admin],
};
